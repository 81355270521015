import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import {
  message,
  Button,
  Form,
  Input,
  InputNumber,
  Modal,
  Select,
  Typography,
  Row, Col, Radio, Space
} from 'antd';
import {
  CloseCircleOutlined,
  EuroCircleOutlined,
  UserOutlined,
  PlusOutlined,
  MinusCircleOutlined
} from '@ant-design/icons';
import { useTranslation } from 'react-i18next';
import '../styles/styles.css';
import {useCreateNewManualLineApiMutation, useEditManualLineApiMutation} from '../../../../services/conciliationLines';
import { defaultRequiredRules} from '../../../../utils/Utils';

const { Text } = Typography;
const { TextArea } = Input;
const { Option } = Select;

const DEVOLUTION_OPERATION_TYPE = 'devolucion';
const CHARGE_OPERATION_TYPE = 'cargo';



export default function NewManualLinesModal({
  conciliation, handleCancel, handleOk, hotel, jobId, action, lineData
}) {
  const { t } = useTranslation();

  const applyFilters = useSelector((state) => state.reducerFiltersPreConciliation);
  const hotelOptions = [];
  const hotels = [];
  const [form] = Form.useForm();

  useEffect(() => {
    const amount = lineData?.importe_cup_comision === null ? lineData?.importe_euros_comision : lineData?.importe_cup_comision;
    if (lineData) {
      form.setFieldsValue({
        hotelCode: lineData.hotel,
        id: lineData.id_preconciliacion,
        operationType: lineData.status,
        conceptoType: lineData.concepto_linea_manual,
        localizator: lineData.reservation_id,
        typeOfConcept: lineData.concepto,
        paymentAmount: amount,
        observation: lineData.observacion,
      });
    }
  }, [lineData]);

  const [
    createManualLine,
    {
      data: createManualLineResult,
      isLoading: isLoadingCreateManualLine,
      error: errorCreateManualLine,
    }
  ] = useCreateNewManualLineApiMutation();

  const [
    editManualLine,
    {
      data: editManualLineResult,
      isLoading: isLoadingEditManualLine,
      error: errorEditManualLine,
    }
  ] = useEditManualLineApiMutation();

  const onFinishForm = async (values) => {
    const operationTypeMultiplier = values?.operationType === DEVOLUTION_OPERATION_TYPE ? 1 : -1;
    const promises = [];
    if (action === 'new') {
      console.log("values", hotels);
      promises.push(createManualLine({
        idJob: jobId,
        idHotel: values?.hotelCode,
        concept: values?.typeOfConcept,
        reservation: values?.localizator,
        nameHotel: hotels?.find((i) => i.value === values.hotelCode).text,
        paymentAmount: values?.paymentAmount,
        conceptoType: values?.conceptoType,
        type: values?.operationType,
        observation: values?.observation,
      }));
    } else {
      promises.push(editManualLine({
        id: lineData.id_preconciliacion,
        idJob: jobId,
        idHotel: hotels?.find((i) => i.text === values.hotelCode).value,
        concept: values?.typeOfConcept,
        reservation: values?.localizator,
        nameHotel: values?.hotelCode,
        paymentAmount: values?.paymentAmount,
        conceptoType: values?.conceptoType,
        type: values?.operationType,
        observation: values?.observation,
      }));
    }
    const result = await Promise.all(promises);
    console.log(result);
  };

  useEffect(() => {
    if (errorCreateManualLine || errorEditManualLine || createManualLineResult?.code === 1 || editManualLineResult?.code === 1) {
      message.error(errorCreateManualLine?.message ?? createManualLineResult?.message);
    } else if (createManualLineResult || editManualLineResult) {
      const successMessage = createManualLineResult?.message || editManualLineResult?.message;
      message.success(successMessage);
      handleOk();
    }
  }, [createManualLineResult, errorCreateManualLine, editManualLineResult, errorEditManualLine]);

  const onOk = () => {
    form.submit();
  };

  const operationTypes = [
    { label: 'Devolución al cliente', value: DEVOLUTION_OPERATION_TYPE },
    { label: 'Cargo al cliente', value: CHARGE_OPERATION_TYPE },
  ];

  const conceptoTypes = [
    { label: 'Coste Financiero', value: "Coste financiero" },
    { label: 'Coste Tecnológico', value: "Coste Tecnológico" },
    { label: 'Coste Marketing', value: "Coste Marketing" },
  ];

  conciliation?.lineas?.forEach((i) => {
    if (i.hotel !== null && i.hotel.length > 0) {
      if (!hotels.some(
        (item) => item.value === i.id_hotel,
      )) {
        hotelOptions.push(
          <Option key={i.id_hotel} value={i.id_hotel}>
            <Text className="text-option-value">
              {i.hotel}
            </Text>
          </Option>
        );
        hotels.push({
          text: i.hotel,
          value: i.id_hotel,
        });
      }
    }
  });

  return (
    <Modal
      title={(
        <Text className="text-title-popconfirm">
          {action === 'new' ? t('text_add_manual_lines') : t('editar_linea_manual_conciliacion')}
        </Text>
      )}
      open
      onCancel={handleCancel}
      closeIcon={<CloseCircleOutlined className="icon" />}
      onOk={onOk}
      okText={action === 'new' ? t('text_add_manual_lines') : t('editar_linea_manual_conciliacion')}
      confirmLoading={isLoadingCreateManualLine || isLoadingEditManualLine}
    >
      <Form
        name="manual_line"
        initialValues={{
          remember: true,
          size: 'middle',
          hotelCode: hotel,
          operationType: 'devolucion',
        }}
        form={form}
        onFinish={onFinishForm}

      >
        <Form.Item
          name="hotelCode"
          rules={defaultRequiredRules(t('error_empty_field'))}
        >
          <Select
            disabled={hotel !== null}
            className="select-modal"
            placeholder={t('hint_hotel_name')}
          >
            {hotelOptions}
          </Select>
        </Form.Item>
        <Form.Item
          name="operationType"
          rules={defaultRequiredRules(t('error_empty_field'))}
        >
          <Select
            className="select-modal"
            allowClear
            placeholder={t('operation_type')}
            optionFilterProp="children"
            fieldNames={{ label: 'label', value: 'value' }}
            options={operationTypes}
          />
        </Form.Item>
        <div style={{ fontWeight: 'bold', fontSize: '16px', marginBottom: '8px' }}>
          Seleccione un concepto:
        </div>
        <Form.Item
          name="conceptoType"
        >
          <Radio.Group>
            <Space direction="vertical">
              {conceptoTypes.map((type) => (
                <Radio key={type.value} value={type.value}>
                  {type.label}
                </Radio>
              ))}
            </Space>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          name="localizator"
          rules={defaultRequiredRules(t('error_empty_field'))}
        >
          <Input
            prefix={<UserOutlined className="icon" />}
            placeholder={t('text_booking_locator')}
            className="input-modal"
          />
        </Form.Item>
        <Form.Item
          name="typeOfConcept"
          rules={defaultRequiredRules(t('error_empty_field'))}
        >
          <Select
            className="select-modal"
            allowClear
            placeholder={t('operation_type_lm')}
            optionFilterProp="children"
            fieldNames={{ label: 'label', value: 'value' }}
            options={applyFilters.concepts_lm}
          />
        </Form.Item>
        <Form.Item
          name="paymentAmount"
          rules={defaultRequiredRules(t('error_empty_field'))}
        >
          <InputNumber
            prefix={<EuroCircleOutlined className="icon" />}
            decimalSeparator=","
            placeholder={t('text_abono_lm')}
            controls={false}
            className="input-modal"
          />
        </Form.Item>
        <Form.Item
          name="observation"
          rules={defaultRequiredRules(t('error_empty_field'))}
        >
          <TextArea
            showCount
            allowClear
            placeholder={t('text_observation')}
            rows={3}
            maxLength={130}
          />
        </Form.Item>
      </Form>
    </Modal>
  );
}

NewManualLinesModal.defaultProps = {
  conciliation: null,
  handleOk: undefined,
  handleCancel: undefined,
  hotel: null,
  action: null,
  lineData: null,
};

NewManualLinesModal.propTypes = {
  conciliation: PropTypes.shape({
    lineas: PropTypes.arrayOf(PropTypes.shape({
      id_hotel: PropTypes.number,
      hotel: PropTypes.string || PropTypes.number,
    })),
    conceptos: PropTypes.arrayOf(PropTypes.shape({
    })),
  }),
  handleOk: PropTypes.func,
  handleCancel: PropTypes.func,
  hotel: PropTypes.string || PropTypes.number,
  jobId: PropTypes.number.isRequired,
  action: PropTypes.string,
  lineData: PropTypes.shape({
    id_preconciliacion: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    hotel: PropTypes.string.isRequired,  
    status: PropTypes.string.isRequired, 
    concepto_linea_manual: PropTypes.string.isRequired, // Tipo de concepto (ejemplo: 'Coste financiero')
    reservation_id: PropTypes.string.isRequired, // Localizador de reserva
    concepto: PropTypes.string.isRequired, // Concepto
    importe_euros_comision: PropTypes.number.isRequired, 
    importe_cup_comision: PropTypes.number.isRequired, 
    observacion: PropTypes.string, // Observación (opcional)
  }),
};

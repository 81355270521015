// import { createSlice } from '@reduxjs/toolkit';
import qs from 'qs';
import { parse, format } from 'date-fns';
import { es } from 'date-fns/locale';
import { t } from 'i18next';
import { api } from './api';
import i18n from '../assets/language/i18n';
import { downloadFile } from '../utils/Utils';
import ConstantsDate from '../constants/ConstantsDate';

export const conciliationLinesApi = api.injectEndpoints({
  tagTypes: ['conciliationLines', 'conciliationLinesMessages'],
  endpoints: (build) => ({
    getConciliationDataApi: build.query({
      query: (data) => ({
        method: 'POST',
        url: '/conciliacion/getConciliacion',
        data: qs.stringify({
          id_job: data.idJob,
          id_hotel: data?.idHotel ? data.idHotel : undefined,
        })
      }),
      transformResponse: (response) => {
        const result = { ...response };
        console.log(response);
        result.lineas = response.lineas.map((reservation) => (
          {
            ...reservation,
            arrival: reservation.arrival === null ? '' : new Date(reservation.arrival),
            arrival_string: reservation.arrival,
            checkout: reservation.checkout === null ? '' : new Date(reservation.checkout),
            checkout_string: reservation.checkout,
            fecha_creacion: reservation.fecha_creacion === null ? '' : new Date(reservation.fecha_creacion),
            fecha_creacion_string: reservation.fecha_creacion,
            nights: Number(reservation.nights ?? '0'),
            booking: Number(reservation.booking ?? '0'),
            canal: reservation.canal === null ? '' : reservation.canal,
            payment_total_usd: Number(reservation.total_abono_usd ?? 0).toFixed(2),
            payment_total_eur: Number(reservation.total_abono_eur ?? 0).toFixed(2),
            reclasificacion_ota: reservation.reclasificacion_ota ?? '',
          }
        ));
        return result;
      },
      transformErrorResponse: () => ({
        message: i18n.t('error_get_resume'),
      }),
      providesTags: ['conciliationLines']
    }),
    createNewManualLineApi: build.mutation({
      query: (data) => ({
        method: 'POST',
        url: '/conciliacion/inserccionManual',
        data: qs.stringify({
          id_job: data?.idJob,
          id_hotel: data?.idHotel,
          concepto: data?.concept,
          reserva: data?.reservation,
          hotel: data?.nameHotel,
          importe: data?.amount,
          tipo_concepto: data?.conceptoType,
          importe_total: data?.paymentAmount,
          observacion: data?.observation,
          tipo_pago: data?.type,
        })
      }),
      transformResponse: (response) => ({ ...response, message: response?.code === 1 ? i18n.t('error_add_manual_line_conciliation') : i18n.t('text_add_manual_line_conciliation') }),
      transformErrorResponse: () => ({
        message: i18n.t('error_add_manual_line_conciliation'),
      }),
      invalidatesTags: ['conciliationLines'],
    }),
    validateManualLineApi: build.mutation({
      query: (data) => {
        console.log(data);
        return {
          method: 'POST',
          url: '/conciliacion/setValidacion',
          data,
          headers: {
            'Content-Type': 'application/json',
          },
        };
      },
      transformResponse: (response) => ({...response, message: response?.msg ?? i18n.t('success_change_status_reservations') }),
      transformErrorResponse: (error) => ({
        message: error.msg ?? i18n.t('error_get_resume'),
      }),
      invalidatesTags: ['conciliationLines'],
    }),

    sendMessageManualLineApi: build.mutation({
      query: (data) => ({
        method: 'POST',
        url: '/arreglo/insertMensaje',
        data: qs.stringify({
          id_arreglo: data?.id_arreglo,
          mensaje: data?.message,
        })
      }),
      transformResponse: (response) => ({ message: response?.msg }),
      transformErrorResponse: (error) => ({
        message: error.msg ?? i18n.t('error_get_resume'),
      }),
      invalidatesTags: ['conciliationLinesMessages'],
    }),

    removeManualLineApi: build.mutation({
      query: (data) => ({
        method: 'POST',
        url: '/conciliacion/borrarInserccionManual',
        data: qs.stringify({
          id_preconciliacion: data?.manualLineId,
        })
      }),
      transformResponse: (response) => ({ ...response, message: response?.code === 1 ? i18n.t('error_remove_manual_line_conciliation') : i18n.t('text_remove_manual_line_conciliation') }),
      transformErrorResponse: (error) => ({
        message: error.msg ?? i18n.t('error_remove_manual_line_conciliation'),
      }),
      invalidatesTags: ['conciliationLines'],
    }),

    editManualLineApi: build.mutation({
      query: (data) => ({
        method: 'POST',
        url: '/conciliacion/editarInserccionManual',
        data: qs.stringify({
          id_preconciliacion: data?.id,
          id_job: data?.idJob,
          concepto: data?.concept,
          importe_total: data?.paymentAmount,
          observacion: data?.observation,
          reservation_id: data?.reservation,
          concepto_linea_manual: data?.conceptoType,
          status: data?.type,
          hotel: data?.nameHotel,
          id_hotel: data?.idHotel,
        })
      }),
      transformResponse: (response) => ({ ...response, message: response?.code === 1 ? i18n.t('error_edit_manual_line_conciliation') : i18n.t('text_edit_manual_line_conciliation') }),
      transformErrorResponse: (error) => ({
        message: error.msg ?? i18n.t('error_remove_manual_line_conciliation'),
      }),
      invalidatesTags: ['conciliationLines'],
    }),

    previewConciliationApi: build.query({
      query: (data) => ({
        method: 'POST',
        url: '/mpdf/previsualizarXML',
        data: qs.stringify({
          id_job: data?.idJob,
        })
      }),
      transformResponse: (response, meta, arg) => {
        const date = format(new Date(), ConstantsDate.DATE_FORMAT_REQUEST, {
          locale: es,
        });
        downloadFile(response, `${arg?.companyName}_${date}.xlsx`);
        return {message: i18n.t('success_download_file')};
      },
      transformErrorResponse: () => ({
        message: i18n.t('error_download_file'),
      }),
    }),
    getMessagesManualLineApi: build.query({
      query: (data) => ({
        method: 'POST',
        url: '/arreglo/getMensaje',
        data: qs.stringify({
          id_arreglo: data?.id_arreglo,
        })
      }),
      transformResponse: (response) => {
        if (response?.code === 1) {
          return {
            message: i18n.t('error_get_resume')
          };
        }
        return response?.mensajes?.map((message) => (
          {
            ...message,
            fecha: parse(message.fecha, 'yyyy-MM-dd HH:mm:ss', new Date()),
          }
        )).sort((a, b) => a.fecha - b.fecha);
      },
      transformErrorResponse: (error) => ({
        message: error.msg ?? i18n.t('error'),
      }),
      providesTags: ['conciliationLinesMessages']
    }),
  }),
});

export const {
  useGetConciliationDataApiQuery,
  useLazyGetConciliationDataApiQuery,
  useCreateNewManualLineApiMutation,
  useEditManualLineApiMutation,
  useValidateManualLineApiMutation,
  useSendMessageManualLineApiMutation,
  useRemoveManualLineApiMutation,
  useLazyPreviewConciliationApiQuery,
  useGetMessagesManualLineApiQuery,
  useLazyGetMessagesManualLineApiQuery,
} = conciliationLinesApi;
